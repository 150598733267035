.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 10%;
}

.answerfield{
  font-size: 1em;
  max-width: 1.75em;
}

.correct-marker{
  color: green;
}

.bunnydiv {
  height: 10em;
  width: auto;
}

.splat {
  height: 10em;
  width: auto;
}

.bunnypic {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: rgb(122, 0, 146);
}

/* mouse over link */
a:hover {
  color: rgb(229, 245, 1);
}

/* selected link */
a:active {
  color: blue;
}

.peza {
  height: 10em;
  width: 10em;
  border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .peza {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.burger-menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 4em; 
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}